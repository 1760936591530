import { formatDistanceToNowStrict, Locale as DateFnsLocaleType } from 'date-fns';
import { enUS, zhCN, de, es, fr, it, ja, ko, nl, ptBR, pl, uk } from 'date-fns/locale';
import { Locale } from '~/types';
import { MS_IN_DAY } from './constants';

export type DateDistanceUnit = 'second' | 'minute' | 'hour' | 'day' | 'month' | 'year' | undefined;

export type OptionsType = {
  addSuffix?: boolean | undefined;
  unit?: DateDistanceUnit;
  roundingMethod?: 'floor' | 'ceil' | 'round' | undefined;
  locale?: globalThis.Locale | undefined;
};

export const DATE_FNS_LOCALES: { [key in Locale]: DateFnsLocaleType } = {
  [Locale.en]: enUS,
  // historically we use zh code to identify Simplified Chinese zh-CN
  [Locale.zh]: zhCN,
  [Locale.de]: de,
  [Locale.es]: es,
  [Locale.fr]: fr,
  [Locale.it]: it,
  [Locale.ja]: ja,
  [Locale.ko]: ko,
  [Locale.nl]: nl,
  // historically we use pt code to identify Brazilian Portuguese pt-BR
  [Locale.pt]: ptBR,
  [Locale.pl]: pl,
  [Locale.uk]: uk,
};

const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const distanceToNow = async (
  days: number,
  locale: Locale = Locale.en,
  config?: {
    unit?: DateDistanceUnit;
    disableCapitalize?: boolean;
  },
): Promise<string> => {
  const localeModule = DATE_FNS_LOCALES[locale];
  const dateFnsLocale = localeModule || enUS;

  const options: OptionsType = {
    roundingMethod: 'floor',
    locale: dateFnsLocale,
  };

  if (config?.unit) {
    options.unit = config.unit;
  }

  let value = formatDistanceToNowStrict(new Date(new Date().getTime() + days * MS_IN_DAY), options);

  if (!config?.disableCapitalize) {
    value = value
      .split(' ')
      .map((word) => capitalizeFirstLetter(word))
      .join(' ');
  }

  return value;
};

export default distanceToNow;
